import React from "react";
import {Link} from "gatsby";
import {TranslationSheet} from "../../i18n/TranslationSheet";
import {parseFn} from "../../utils/parseFn";

interface Props {
    slug: string;
    title: string;
    value: number;
    link: string;
    subtitle: string;
    referredPage: string;
    t: TranslationSheet;
}

const Card: React.FC<Props> = ({slug, title, value, link, subtitle, referredPage, t}) => {
    return (
        <div className="px-6 py-6 mb-6 bg-white rounded-lg shadow-md relative">
            <div className="md:absolute md:right-6 flex">
                <span className="px-2 py-1 mb-3 md:mb-0 bg-green-700 text-white font-bold rounded">
                    {t.OF_VALUE_PREFIX} {parseFn(t.VALUE_LABEL)(value)}
                </span>
            </div>
            <div>
                <Link
                    to={"/" + slug + "/"}
                    className="plausible-event-name=List+Card+Link+Click text-2xl text-gray-700 font-bold hover:underline"
                >
                    {title}
                </Link>
                <p className="mt-2 text-gray-600">{subtitle}</p>
            </div>
            <div className="flex mt-4">
                <Link
                    className="plausible-event-name=List+Card+Link+Click flex items-center h-12 px-4 mt-4 text-xl font-bold text-indigo-700 transition-colors duration-150 border-indigo-700 border-2 rounded-lg focus:shadow-outline hover:bg-indigo-800 hover:text-indigo-100"
                    to={"/" + slug + "/"}
                >
                    {t.READ_MORE_BUTTON}
                </Link>

                <a
                    className="plausible-event-name=List+CTA+Click ml-3 flex items-center h-12 px-4 mt-4 text-xl font-bold text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t.CLAIM_BUTTON}
                </a>
            </div>
        </div>
    );
};

export default Card;
