import React from "react";
import {Link} from "gatsby";
import {TranslationSheet} from "../i18n/TranslationSheet";
import {parseFn} from "../utils/parseFn";

const Header: React.FC<{valueSum: number; t: TranslationSheet}> = (props) => {
    const {valueSum, t} = props;

    return (
        <header className="flex flex-col md:flex-row justify-between mt-3 bg-contain bg-no-repeat bg-right md:bg-header">
            <div className="flex flex-col content-center justify-between py-3 sm:w-full md:w-1/2">
                <h1 className="text-4xl">
                    {t.CTA_TEXT}
                    {": "}
                    <strong className={"font-bold text-4xl text-green-700"}>
                        {parseFn(t.VALUE_LABEL)(valueSum)}
                    </strong>
                </h1>

                <Link
                    className="plausible-event-name=Homepage+CTA+Click flex items-center h-14 w-full sm:w-min px-4 mt-6 text-xl font-bold text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800"
                    to={t.AMAZON_POST_HREF}
                >
                    <span className="w-full text-center">{t.CTA_BUTTON}</span>
                </Link>
            </div>
        </header>
    );
};

export default Header;
