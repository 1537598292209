import React from "react";
import {Link} from "gatsby";
import {TranslationSheet} from "../i18n/TranslationSheet";

const InformativePosts: React.FC<{nodes: any; t: TranslationSheet}> = (props) => {
    const {nodes, t} = props;

    return (
        <div className="flex flex-col mt-6 mb-3">
            <h3 className="text-xl font-bold text-gray-700 md:text-2xl">
                {t.INFORMATIVE_POSTS_HEADING}
            </h3>

            <p className={"mb-6"}>{t.INFORMATIVE_POSTS_SUBHEADING}</p>

            <ul>
                {nodes.map((informativePost) => (
                    <li key={informativePost.id} className={"mb-3"}>
                        <Link
                            className="text-indigo-700 text-bold text-xl"
                            to={"/" + informativePost.slug + "/"}
                        >
                            {informativePost.title}
                        </Link>
                        <p>{informativePost.subtitle}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InformativePosts;
