import React from "react";
import {Link} from "gatsby";
import {ContentfulBlogPost} from "../../graphql-types";

const BlogPostsGrid: React.FC<{blogPosts: {node: ContentfulBlogPost}[]}> = ({blogPosts}) => {
    return (
        <div className="mx-auto grid gap-5 lg:grid-cols-3 md:grid-cols-2">
            {blogPosts.map((blogPost) => {
                return (
                    <Link
                        to={"/" + blogPost.node.slug + "/"}
                        key={blogPost.node.id}
                        className="group flex flex-col overflow-hidden rounded-lg shadow-lg"
                    >
                        <div className="flex-shrink-0">
                            <img
                                className="h-48 w-full object-cover group-hover:brightness-125"
                                src={blogPost.node.image.url}
                                alt={blogPost.node.title}
                            />
                        </div>
                        <div className="flex flex-1 flex-col justify-between bg-white p-6">
                            <div className="flex-1">
                                <div className="mt-2 block">
                                    <p className="text-xl font-semibold text-gray-900">
                                        {blogPost.node.title}
                                    </p>
                                    <p className="mt-3 text-base text-gray-500">
                                        {blogPost.node.subtitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export default BlogPostsGrid;
