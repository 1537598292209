import React from "react";
import Card from "components/PostsCards/Card";
import {TranslationSheet} from "../../i18n/TranslationSheet";
import {parseFn} from "../../utils/parseFn";

const PostsCards: React.FC<{nodes: any; t: TranslationSheet}> = (props) => {
    const {nodes, t} = props;

    return (
        <div className="flex flex-col">
            <h2 className="text-xl font-bold text-gray-700 md:text-2xl">{t.OFFERS_LIST_COUNTER}</h2>

            <p className={"mb-6"}>{parseFn(t.OFFERS_LIST_COUNTER_SUBHEADER)(nodes.length)}</p>

            {nodes.map((post) => (
                <Card t={t} key={post.id} {...post} />
            ))}
        </div>
    );
};

export default PostsCards;
