import React from "react";
import Layout from "components/Layout";
import Header from "components/Header";
import PostsCards from "components/PostsCards";
import {contentfulRenderer} from "../utils/contentfulRenderer";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import {parseFn} from "../utils/parseFn";
import InformativePosts from "components/InformativePosts";
import BlogPostsGrid from "components/BlogPostsGrid";
import {Helmet} from "react-helmet";

const HomePage = (props) => {
    const {
        pageContext: {
            allContentfulPost: {nodes},
            allContentfulInformativePost: {nodes: informativePostsNodes = []} = {},
            contentfulHomePageSeo: {headerSeoText, postOffersSeoText},
            blogPosts,
            t
        }
    } = props as PropsWithTranslationSheet & any;

    const valueSum = nodes.reduce((accumulator, current) => accumulator + current.value, 0);

    return (
        <Layout
            t={t}
            title={t.INDEX_TITLE}
            metaDescription={parseFn(t.INDEX_META_DESCRIPTION)(nodes.length, valueSum)}
        >
            <Helmet>
                <link rel="alternate" href="https://darmowe-wyprawki.pl/" hrefLang="pl" />
                <link rel="alternate" href="https://baby-boxes.com.ua/" hrefLang="uk" />
            </Helmet>

            <Header t={t} valueSum={valueSum} />

            <main>
                <article
                    id={"description"}
                    className="prose pt-10 pb-14 text-center mx-auto max-w-xl"
                >
                    {contentfulRenderer(headerSeoText)}
                </article>

                <PostsCards t={t} nodes={nodes} />

                {informativePostsNodes.length ? (
                    <InformativePosts t={t} nodes={informativePostsNodes} />
                ) : null}

                {blogPosts.length ? (
                    <>
                        <h2
                            id={"blog"}
                            className="mt-8 text-xl font-bold text-gray-700 md:text-2xl"
                        >
                            Blog
                        </h2>
                        <p className={"mb-6"}>{t.BLOG_SUBHEADING}</p>

                        <BlogPostsGrid blogPosts={blogPosts} />
                    </>
                ) : null}

                <hr className={"my-8"} />

                <div className={"prose max-w-full text-justify"}>
                    {contentfulRenderer(postOffersSeoText)}
                </div>
            </main>
        </Layout>
    );
};

export default HomePage;
